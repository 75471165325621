import self from '../img/self.png';
import { colors } from '../colors/mainGradient';

export const info = {
  firstName: 'Miftah',
  lastName: 'GanzZ',
  initials: 'MG', // the example uses first and last, but feel free to use three or more if you like.
  position: 'a Junior Developer',
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: '🇨🇦 🍁 🌃',
      text: 'based in the West Java, Indonesia',
    },
    {
      emoji: '💻 🧑‍💻️ ⌨️',
      text: 'Junior Developer',
    },
    {
      emoji: '📧 📨 📩',
      text: 'miftahganzz01@gmail.com',
    },
  ],
  socials: [
    {
      link: 'https://github.com/miftahganzz',
      icon: 'fab fa-github',
      label: 'github',
    },
    {
      link: 'https://tiktok.com/@miftahbotz',
      icon: 'fab fa-tiktok',
      label: 'tiktok',
    },
    {
      link: 'https://t.me/@miftahganzz',
      icon: 'fab fa-telegram',
      label: 'telegram',
    }
  ],
  bio: "Hello! I'm Miftah. I'm a Junir Developer. I enjoy building web applications and learning new technologies. I'm currently working as a Junior Developer. I'm currently based in West Java, Indonesia.",
  skills: {
    languages: ['TypeScript', 'JavaScript', 'PHP', 'Python', 'HTML/CSS'],
    frameworks: [
      'React',
      'Taildwind Css',
      'Vue',
      'Next JS',
      'Express',
      'Bootstrap/jQuery'
    ],
    databases: ['MySQl', 'MongoDB'],
    cloudServices: ['Heroku', 'Vercel', 'Google Analytics', 'Netlify'],
    tools: [
      'Git',
      'Docker',
      'Swagger',
      'npm',
      'yarn'
    ],
  },
  hobbies: [
    {
      label: 'Video Games',
      emoji: '🎮',
    },
    {
      label: 'Reading',
      emoji: '📕',
    },
    {
      label: 'Coding',
      emoji: '👨‍💻',
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    {
      title: 'Rest API ITzpire',
      live: 'https://itzpire.site', //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: null, // this should be a link to the **repository** of the project, where the code is hosted.
      image: 'https://cdn.miftah.biz.id/file/65e3b127c0a9f.png',
    },
    {
      title: 'Dolers Website',
      live: 'https://dolers.miftah.xyz', //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: 'https://github.com/miftahganzz/Dolers-Website', // this should be a link to the **repository** of the project, where the code is hosted.
      image: 'https://i.ibb.co/xmqnZLt/Macbook-Air-dolers-miftah-xyz.png',
    },
    {
      title: 'Shortener Website',
      live: 'https://shortin.my.id',
      source: 'https://github.com/miftahganzz/Shortener-Website',
      image: 'https://i.ibb.co/yV26dx8/Macbook-Air-shortin-my-id.png',
    },
    {
      title: 'AI Bot Telegram',
      live: null,
      source: 'https://github.com/miftahganzz/AI-Bot-Telegram-v2',
      image: null,
    },
  ],
};
